<template>
  <div>
    <d-alert class="alert-royal-blue" show>
      <i class="fa fa-info mx-2"></i>
      <strong>How you doin'?</strong> I'm just a friendly, good-looking notification message and I come in all the
      colors you can see below. Pretty cool, huh?
    </d-alert>

    <d-container fluid>
      <pageTitle :subtitle="'Account'"
                 :title="'Personalia'"/>
      <!--    <h1 @click="$goTo('sell-2')">UserProfile page 1 - 2</h1>-->
      <div class="cards">
        <div class="border-bottom card-header"><h6 class="m-0">Account Details</h6></div>
        <div class="list-group list-group-flush">
<!--          <h1 @click="goToPage3">test</h1>-->
          <div class="p-3 list-group-item">
            <p>data</p>
            <!--            <vTable :data="tableData"-->
            <!--                    :fields="tableFields"-->
            <!--                    :filters="tableFilters"-->
            <!--                    :actions="tableActions"-->
            <!--            />-->
          </div>
        </div>
      </div>
    </d-container>
  </div>
</template>

<script>
import router from './../router'

export default {
  name: 'UserProfileHome',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    goToPage3() {
      // console.log(this.$goToModuleRoute(router, 'sell-3'))
      this.$router.push({name: this.$goToModuleRoute(router, 'sell-3')})
    }
  }
}
</script>

<style scoped>
.cards {
  background-color: #FFF;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  border-radius: 5px;
}
</style>
